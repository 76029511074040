import * as React from "react"

import Layout from "../components/Organisms/layout"
import Seo from "../components/seo"
import Inner from "~/components/Organisms/inner"
import ContentsTextBlock from "~/components/Molecules/contentsBlock"
import ContentsFooter from "~/components/Molecules/contentsFooter"


const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Inner>
      <ContentsTextBlock 
        title="404: Not Found"
        lead="お探しのページは見つかりませんでした。"
      />
    </Inner>
    <ContentsFooter />
  </Layout>
)

export default NotFoundPage
